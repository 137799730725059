import React from 'react';
import {Container, Header, Segment,} from 'semantic-ui-react';
import styles from "./support.module.css";
import {graphql, StaticQuery} from "gatsby";

class SupportIntro extends React.Component {
    render() {
        return (
            <StaticQuery query={supportIntroQuery}
                         render={
                             data => {
                                 const Intro = data.allMarkdownRemark.edges.map(i =>
                                     <Container fluid className={styles.container}>
                                         <Segment className={styles.introBox}>
                                             <Header as={'h3'}>
                                                 {i.node.frontmatter.title}
                                             </Header>
                                             <Container fluid dangerouslySetInnerHTML={{__html: i.node.html}}>
                                             </Container>
                                         </Segment>
                                     </Container>
                                 );
                                 return(<>{Intro}</>)
                             }
                         }
            />
        );
    }
}

export default SupportIntro;

const supportIntroQuery = graphql`
query supportIntroQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "support-page" }}
      }
      limit: 1
      ){
      edges {
        node {
            html
            frontmatter {
                title
            }
        }
      }
    }
}
`;