import React from 'react';
import {Container, Header, Segment} from 'semantic-ui-react';
import {graphql, StaticQuery} from "gatsby";
import styles from "../../components/support/support.module.css";
import ButtonNav from "../utils/buttons/ButtonNav";

class SupportEntries extends React.Component {
    render() {
        return (
            <StaticQuery query={supportEntryQuery}
                         render={
                             data => {
                                 const Entries = data.allMarkdownRemark.edges.map(i =>
                                     <>
                                         <p id={i.node.frontmatter.link} className={styles.anchor}/>
                                         <div className={styles.article}>
                                     <Segment>
                                         <Header as={'h3'} textAlign={'center'} >
                                             {i.node.frontmatter.title}
                                         </Header>
                                         <Container className={styles.container} fluid dangerouslySetInnerHTML={{__html: i.node.html}} />
                                             <ButtonNav link={'/kontakt/'}>Kontaktieren</ButtonNav>
                                     </Segment>
                                         </div>
                                         </>
                                 );
                                 return (
                                     <Container fluid className={styles.wrapper}>
                                             {Entries}
                                     </Container>
                                 )
                             }
                         }
            />


        );
    }
}

export default SupportEntries;

const supportEntryQuery = graphql`
query supportEntryQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "support-possibility" }}
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
            html
            frontmatter {
                title
                link
            }
        }
      }
    }
}
`;