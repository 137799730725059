import React from "react";
import styles from "./mobilenavbutton.module.css";
import {Button, Icon} from "semantic-ui-react";
import DesktopContainer from "../../../container/DesktopContainer";
import MobileTabletContainer from "../../../container/MobileTabletContainer";

const ButtonNav = ({link, children}) => (
    <>
        <MobileTabletContainer className={styles.mobile}>
            <Button as={'a'} href={link} className={styles.navButton} icon labelPosition={'left'}><Icon
                name={'arrow right'}/> {children}</Button>
        </MobileTabletContainer>
        <DesktopContainer className={styles.desktop}>
            <Button as={'a'} href={link} className={styles.navButton} icon labelPosition={'left'}><Icon
                name={'arrow right'}/> {children}</Button>
        </DesktopContainer>
    </>

);


export default ButtonNav;