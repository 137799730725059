import React from 'react';
import {Container, Grid, Header} from 'semantic-ui-react';
import styles from "./support.module.css";
import RaisedSegment from "../utils/RaisedSegment";
import {graphql, StaticQuery} from "gatsby";
import Img from "../utils/Img";
import ButtonNav from "../utils/buttons/ButtonNav";

class Support extends React.Component {
    render() {
        return (
            <StaticQuery query={supportPossibilityQuery}
                         render={
                             data => {
                                 const Intro = data.allMarkdownRemark.edges.map(i =>
                                     <Grid.Column className={styles.box}>
                                         <RaisedSegment>
                                             <Header as={'h3'} textAlign={'center'}>
                                                 {i.node.frontmatter.title}
                                             </Header>
                                             {
                                                 !!i.node.frontmatter.image &&
                                                 <Img fluid={i.node.frontmatter.image.childImageSharp.fluid}/>
                                             }
                                             <Container className={styles.container}
                                                        style={{marginTop:'1em'}}
                                                        fluid dangerouslySetInnerHTML={{__html: i.node.html}} />
                                             {
                                                 !!i.node.frontmatter.link &&
                                                 <ButtonNav link={i.node.frontmatter.link}>Mehr erfahren</ButtonNav>
                                             }
                                         </RaisedSegment>
                                     </Grid.Column>
                                 );
                                 return (
                                     <Grid stackable style={{marginBottom: '1em'}}>
                                         <Grid.Row columns={2} stretched>
                                             {Intro}
                                         </Grid.Row>
                                     </Grid>
                                 )
                             }
                         }
            />


        );
    }
}

export default Support;

const supportPossibilityQuery = graphql`
query supportPossibilityQuery {
    allMarkdownRemark( 
      filter:{
        frontmatter: {type: { eq: "support-entry-possibility" }}
      }
      sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
            html
            frontmatter {
                title
                link
                image {
                  childImageSharp {
                    fluid(maxHeight: 1000) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
            }
        }
      }
    }
}
`;