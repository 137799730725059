import React, {Component} from "react";
import '../styles/semantic.min.css';
import LayoutContainer from "../container/LayoutContainer";
import SEO from "../components/seo/Seo";
import MainNavigation from "../components/navigation/MainNavigation";
import Footer from "../components/footer/Footer";
import Support from "../components/support/support";
import PageHeader from "../components/utils/PageHeader";
import SupportIntro from "../components/support/support-intro";
import SupportEntries from "../components/support/support-entries";
import {Container} from "semantic-ui-react";
import DesktopContainer from "../container/DesktopContainer";
import MobileTabletContainer from "../container/MobileTabletContainer";
import layout from "../templates/layout.module.css";

class Unterstuetzen extends Component {
    render() {
        return (
            <LayoutContainer>
                <SEO title={'Unterstützen'}/>
                <MobileTabletContainer>
                    <MainNavigation/>
                    <PageHeader title={'Unterstützen'}/>
                    <SupportIntro/>
                    <Support/>
                    <SupportEntries/>
                    <Footer/>
                </MobileTabletContainer>
                <DesktopContainer>
                    <div className={layout.pageWrapper}>
                    <MainNavigation/>
                    <PageHeader title={'Unterstützen'}/>
                    <Container>
                        <SupportIntro/>
                        <Support/>
                        <SupportEntries/>
                    </Container>
                        <Footer/>
                    </div>
                </DesktopContainer>
            </LayoutContainer>
        )
    }
}

export default Unterstuetzen;